import { DefaultConfig } from './types';
import { defaultConfig } from './default';

export const defaultJuniper: DefaultConfig = {
  ...defaultConfig,
  brand: 'juniper-jp',
  currency: 'JPY',
  currencyLocales: 'ja-JP',
  country: 'Japan',
  requireInternationalClinicianPhone: true,
  defaultTimezone: 'Asia/Tokyo',
  syncConsultationsEnabled: true,
  isDigitalSigningEnabled: true,
};
